export const ADD_NEW_CLIENT = 'ADD_NEW_CLIENT';
export const ERROR_ADD_NEW_CLIENT = 'ERROR_ADD_NEW_CLIENT';
export const IS_DEFAULT_STATE = 'IS_DEFAULT_STATE';
export const ADD_QUESTION = 'ADD_QUESTION';
export const GET_ADMIN_EXAMS = "GET_ADMIN_EXAMS";
export const GET_USERS = "GET_USERS";
export const GET_INSTITUTES = "GET_INSTITUTES";
export const GET_QUESTIONS = "GET_QUESTIONS";
export const GET_LISTENING = "GET_LISTENING";
export const GET_ADMIN_RESULTS = "GET_ADMIN_RESULTS";
export const GET_ADMIN_REPORT = "GET_ADMIN_REPORT";
export const GET_ADMIN_EXAM_ACCESS = "GET_ADMIN_EXAM_ACCESS";
export const QUESTION_ADD_LOADING = "QUESTION_ADD_LOADING";
