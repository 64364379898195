import React, { useEffect, useState } from 'react';
import ListTable from '../../../../common/Table';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from './services/ReportActions';

const ReportList = () => {
    const reportList = useSelector((state: any) => state.adminReducer.reports);
    const user = useSelector((state: any) => state.authReducer.user);

    const dispatch = useDispatch();
    const [report, setReport] = useState([]);

    useEffect(() => {
        user.uid && loadReport();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    useEffect(() => {
        setReport(reportList);
    }, [reportList])

    const loadReport = async (limit: number = 20) => {
        await dispatch(actions.getReportOfInstitute(limit));
    }

    const columns = [
        {
            name: 'user',
            align: 'left',
            label: 'Username',
            field: 'username',
            flexVal: 1,
            sortable: true,
            splice: 20,
        }
        , {
            name: 'exam',
            align: 'left',
            label: 'Exam Title',
            field: 'examTitle',
            flexVal: 1,
            sortable: true,
            splice: 20,
        }
        , {
            name: 'Date',
            align: 'center',
            label: 'Date',
            field: 'timestamp',
            flexVal: 1,
            date: true

        }
    ]
    const inputHandler = (e: any) => {
        loadReport(e.target.value)
    };

    return (
        <section className="wrapper issues full-height full-width flex column py-md">
            <header className={'flex justify-between items-center mb-md'}>
                <div className="title-sm bold text-primary">Report</div>
                <div className="flex wrap">
                    <div className="form-group my-md full-width">
                        <label>Result Limit</label>
                        <select name="type" onChange={inputHandler}>
                            <option value="20">20</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="200">200</option>
                        </select>
                    </div>
                </div>
            </header>
            <div className="table-area pt-md flex-1">
                <ListTable
                    columns={columns}
                    rows={report}
                    paginate={20}
                />
            </div>
        </section>
    )
}

export default ReportList;
