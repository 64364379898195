import { setToasterState } from '../../../../../store/action';
import store from '../../../../../store/store';
import {
  GetRequest,
  PostRequest,
  PutRequest,
} from '../../../../../plugins/axios';

const userUrl = process.env.REACT_APP_API_BASE_URL + 'users';
export const adminGetAllInstitutesSuccess = (payload: any) => {
  return {
    type: 'GET_INSTITUTES',
    payload: payload,
  };
};

export const adminGetAllInstitutes = () => (dispatch: any) => {
  const userID = store.getState().authReducer.user._id;
  if (userID) {
    GetRequest(`${userUrl}/getAllInstitutes/${userID}`, {}, {})
      .then((res) => {
        dispatch(adminGetAllInstitutesSuccess(res.data));
      })
      .catch((err) => {
        dispatch(
          setToasterState({
            appear: true,
            title: 'error',
            name: 'Fetch Error',
            message: 'Error cannot get Exams',
          })
        );
      });
  }
};

export const adminAddInstitute = (payload: any) => (dispatch: any) => {
  const userID = store.getState().authReducer.user._id;
  if (userID) {
    PostRequest(`${userUrl}/createInstitute/${userID}`, payload, {})
      .then((res) => {
        dispatch(adminGetAllInstitutes());
      })
      .catch((err: Error) => {
        dispatch(
          setToasterState({
            appear: true,
            title: 'error',
            name: 'Fetch Error',
            message: err.message,
          })
        );
      });
  }
};
export const adminEditInstitute = (instituteId: string, payload: any) => (
  dispatch: any
) => {
  const userID = store.getState().authReducer.user._id;
  if (userID) {
    PutRequest(`${userUrl}/updateInstitute/${userID}`, payload, {})
      .then((res) => {
        dispatch(adminGetAllInstitutes());
        dispatch(
          setToasterState({
            appear: true,
            title: 'success',
            name: 'Update Sucess',
            message: 'Sucessfully Updated Institute',
          })
        );
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          setToasterState({
            appear: true,
            title: 'error',
            name: 'Fetch Error',
            message: 'err',
          })
        );
      });
  }
};
