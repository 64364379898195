import React, { useState } from 'react';
import img from '../../../../../assets/default.png';
import Modal from '../../../../../common/modal';
import ProfilePictureForm from './ProfilePictureForm';

const ProfilePicture = (props: any) => {
    const [showModal, setShowModal] = useState(false);
    const user = props.user;

    return (
        <div className="profile-picture-card flex justify-center items-center column">
            <img className="ma-lg" src={user?.image ? `${process.env.REACT_APP_API_BASE_URL}${user.image}` : img} alt="" />
            <span className="link" onClick={() => setShowModal(true)}>Change Image</span>

            <Modal show={showModal} title={'Change Image'}
                closeModal={() => setShowModal(false)}>
                <ProfilePictureForm
                    user={user}
                    closeModal={() => setShowModal(false)}
                />
            </Modal>
        </div>
    )
}

export default ProfilePicture
