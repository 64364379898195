import React, { useEffect, useState } from 'react';
import MainRoute from '../routes';
import Toaster from '../common/toaster';
import { useDispatch } from 'react-redux';
import * as actions from '../store/action';
import Loading from '../common/loading';

const MainLayout = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        reauthenticate().then(r => true);
        // eslint-disable-next-line
    }, [])

    const reauthenticate = async () => {
        await dispatch(actions.reauthenticate())
        setLoading(false)
    }
    return (
        <>
            {loading ? <Loading isLoading={loading} /> : <MainRoute />}
            <Toaster />
        </>
    )
}

export default MainLayout;
