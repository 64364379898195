import { auth } from '../firebaseconfig';

export function Signup(email: string, password: string) {
  return auth.createUserWithEmailAndPassword(email, password);
}

export function Signin(email: string, password: string) {
  return auth.signInWithEmailAndPassword(email, password);
}

export function SignOut() {
  return auth.signOut();
}

export function UpdateProfileDetails(name: string) {
  const user = auth.currentUser;
  user?.updateProfile({
    displayName: name,
  });
}
