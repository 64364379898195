import React from 'react';
import './App.css';
import MainLayout from './hoc/MainLayout';

function App() {
  return (
    <>
      <MainLayout />
    </>
  );
}

export default App;
