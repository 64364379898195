import React, { useEffect, useState } from 'react';
import { ValidateForm, ValidateInput } from '../../../../../utils/customHooks/validateForm';
import { Exam } from '../../../../common/model/Exam';
import ExamRules from './examRules';

interface IEditExam {
    editObj: any,
    mode: string,
    closeModal: Function,
    editExam: Function,
}

const initialState: Exam = {
    title: '',
    type: '',
    status: 0
}

const EditExam = (props: IEditExam) => {
    const [exam, setUser] = useState(initialState);
    const [errors, setErrors] = useState(initialState);

    useEffect(() => {
        if (props.mode === 'edit') {
            setUser(props.editObj)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const inputHandler = (e: any) => {
        setErrors({
            ...errors,
            [e.target.name]: ''
        })
        setUser({
            ...exam,
            [e.target.name]: e.target.value
        });
    }

    const confirmAction = async () => {
        let errorMsgs: any = ValidateForm(exam, ExamRules);
        setErrors({ ...errorMsgs });
        let validated = Object.values(errorMsgs).join('').length === 0;
        if (validated) {
            await props.editExam(exam);
            props.closeModal();
        }
    }

    const inputValidation = (e: any) => {
        let errorMsg = ValidateInput(e.target.name, e.target.value, ExamRules);
        setErrors({
            ...errors,
            [e.target.name]: errorMsg
        });
    }

    return (
        <div className="edit-client dialog-content">
            <div className="dialog-content-area flex">
                <div className="form-area my-md full-width">
                    <div className="form-group my-md full-width">
                        <label>Title</label>
                        <input
                            name="title"
                            type="text"
                            onChange={inputHandler}
                            value={exam.title}
                            onBlur={inputValidation} />
                        {errors.title !== '' ? <span className="error-text">{errors.title}</span> : ''}
                    </div>
                    <div className="form-group my-md full-width">
                        <label>Exam Type</label>
                        <select name="type"
                            value={exam.type}
                            onChange={inputHandler}
                            onBlur={inputValidation}>
                            <option value="private">Private</option>
                            <option value="public">Public</option>
                        </select>
                        {errors.type !== '' ? <span className="error-text">{errors.type}</span> : ''}
                    </div>
                </div>
            </div>
            <div className="button-area flex justify-end mtyar-lg">
                <button className="btn primary" onClick={confirmAction}>Edit Exam</button>
                <button className="btn primary outlined ml-xs" onClick={() => props.closeModal()}>Cancel</button>
            </div>
        </div>
    )
}

export default EditExam
