import React, { useEffect, useState } from 'react';
import ListTable from '../../../../common/Table';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../../store/action';
import Modal from '../../../../common/modal';
import Confirmation from '../../../../common/modal/Confirmation';
import EditInstitute from './components/EditInstitute';
import AddInstitute from './components/AddInstitute';

const Institutes = () => {
    const instituteList = useSelector((state: any) => {
        return state.adminReducer.institutes;
    });
    // const usersList: any = [];
    const user = useSelector((state: any) => {
        return state.authReducer.user;
    });
    const dispatch = useDispatch();
    const [institutes, setInstitutes] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [editUserObj, setEditUserObj] = useState({ _id: '-1' });
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [modalMode, setModalMode] = useState('add');

    useEffect(() => {
        loadInstitutes();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    useEffect(() => {
        setInstitutes(instituteList);
    }, [instituteList])

    const loadInstitutes = async () => {
        await dispatch(actions.adminGetAllInstitutes());
    }

    const deleteAction = (obj: any) => {
        setShowConfirmation(true);
        setEditUserObj(obj)
    }

    const deleteConfirmation = async () => {
        // await dispatch(actions.clientDeleteUser(editUserObj.id));
        setShowConfirmation(false);
    }

    const addAction = () => {
        setModalMode('add');
        setShowModal(true);
    }

    const editInstitute = async (payload: any) => {
        await dispatch(actions.adminEditInstitute(editUserObj._id, payload));
    }

    const editAction = (obj: any) => {
        setModalMode('edit');
        setEditUserObj(obj);
        setShowModal(true);
    }

    const columns = [
        {
            name: 'name',
            align: 'left',
            label: 'Name of Institute',
            field: ['firstname', 'lastname'],
            flexVal: 1,
            multi: true,
            sortable: true
        }, {
            name: 'email',
            align: 'left',
            label: 'Email',
            field: 'email',
            flexVal: 1,
            sortable: true
        }, {
            name: 'add_role',
            align: 'center',
            label: 'Institute Role',
            field: 'institute_role',
            flexVal: 1,
            sortable: true
        }, {
            name: 'status',
            align: 'center',
            label: 'Status',
            field: 'status',
            flexVal: 1,
            sortable: true,
            boxed: true
        }
    ]

    return (
        <section className="wrapper issues full-height full-width flex column py-md">
            <header className={'flex justify-between items-center mb-md'}>
                <div className="title-sm bold text-primary">Institutes</div>
                <button className="btn primary" onClick={addAction}>Add Institute</button>
            </header>
            <div className="table-area pt-md flex-1">
                <ListTable
                    columns={columns}
                    rows={institutes}
                    editAction={editAction}
                    deleteAction={deleteAction}
                    paginate={10}
                />
            </div>
            {
                modalMode === 'add' ?
                    <Modal show={showModal} title={'Add Institute'}
                        closeModal={() => setShowModal(false)}>
                        <AddInstitute closeModal={() => setShowModal(false)} mode={modalMode} />
                    </Modal> :
                    <Modal show={showModal} title={'Edit Institute'}
                        closeModal={() => setShowModal(false)}>
                        <EditInstitute
                            editInstitute={editInstitute}
                            editObj={editUserObj}
                            closeModal={() => setShowModal(false)}
                            mode={modalMode}
                        />
                    </Modal>
            }
            <Confirmation title="Confirm" content="Are you sure you want to delete this item?" show={showConfirmation}
                confirm={deleteConfirmation}
                closeModal={() => setShowConfirmation(false)} />
        </section>
    )
}

export default Institutes;
