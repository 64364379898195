import React from 'react';
import { NavLink } from "react-router-dom";
import userManagement from "../../../../../assets/svgs/icon/group.svg";
import examlist from "../../../../../assets/svgs/icon/score.svg";
import result from "../../../../../assets/svgs/icon/exam.svg";


import { useSelector } from "react-redux";
import Tooltip from '../../../../../common/tooltip';

const sideBarList = [
    {
        path: '/student/exams',
        icon: examlist,
        label: 'Exam List',
        role: 0
    },
    {
        path: '/student/results',
        icon: result,
        label: 'Results',
        role: 0
    },
    {
        path: '/admin/exams',
        icon: examlist,
        label: 'Exam List',
        role: 1
    },
    {
        path: '/admin/users',
        icon: userManagement,
        label: 'Manage User',
        role: 1
    },
    // {
    //     path: '/admin/institutes',
    //     icon: manageInstitute,
    //     label: 'Manage Institute',
    //     role: 1
    // },
    {
        path: '/admin/reports',
        icon: userManagement,
        label: 'View Report',
        role: 1
    },
];

const ClientSidebar = () => {
    const role = useSelector((state: any) => state.authReducer.user.role);

    return (
        <aside className="sidebar flex column items-center full-height">
            <div className="sidebar-items flex-1 flex column items-center">
                {
                    sideBarList.map((d, i) => {
                        if (d.role === role) {
                            return <NavLink key={i} to={d.path} className="side-bar-item flex justify-between items-center py-md pa-sm" exact>

                                <Tooltip label={d.label} location="top right">
                                    <img src={d.icon} alt="" />
                                </Tooltip>
                                <span>
                                    {d.label}
                                </span>
                            </NavLink>
                        }
                        return <></>
                    })
                }
            </div>
        </aside>
    );
}

export default ClientSidebar;
