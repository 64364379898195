import React from 'react'
import { useSelector } from 'react-redux';
import { UserType } from '../../../../common/enum/UserType.enum';
import ClientDetails from './components/ClientDetails';
import ProfilePicture from './components/ProfilePicture';

export const UserProfile = () => {
    const user = useSelector((state: any) => {
        return state.authReducer.user;
    });

    return (
        <div className="issues full-height flex full-width py-md">
            <div className="ml-lg mr-lg mb-lg full-width">
                <header className={'flex justify-between items-center mb-md'}>
                    <div className="title-sm bold text-primary">Your Profile</div>
                </header>
                <div className="flex card bg-light outline">
                    <div style={{ width: '40%' }}>
                        <ClientDetails user={user} />
                    </div>
                    <div className="mr-md ml-md" style={{ border: '1px solid #ccc' }} />
                    <div className="ml-md">
                        {(user.role === UserType.ADMIN) &&
                            <ProfilePicture user={user} />}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserProfile
