import { setToasterState } from '../../../common/toaster/services/toasterAction';
import { GetRequest, PutRequest } from '../../../plugins/axios';
import { User } from '../../common/model/User';
import store from '../../../store/store';
import { auth } from '../../../firebaseconfig';
import {
  AddData,
  GetDocumentData,
  UpdateData,
} from '../../../plugins/firebasefirestore';
import { Signin, SignOut } from '../../../plugins/firebaseauth';
const changePasswordUrl = `${process.env.REACT_APP_API_BASE_URL}users/changePassword`;
const userUrl = 'users';

export const loginSuccess = (payload: any) => {
  return {
    type: 'LOGIN_SUCCESS',
    payload: payload,
  };
};
export const instituteInfoSuccess = (payload: any) => {
  return {
    type: 'GET_INSTITUTE_INFO',
    payload: payload,
  };
};

export const logoutSuccess = () => {
  return {
    type: 'LOGOUT',
  };
};

export const loginFailed = () => {
  return {
    type: 'LOGIN_FAIL',
  };
};

export const changeUser = (payload: User) => {
  return {
    type: 'CHANGE_USER',
    payload: payload,
  };
};

export const setLoading = (payload: boolean) => {
  return {
    type: 'LOADING',
    payload: payload,
  };
};

export const changeOwnPassword = (data: any) => (dispatch: any) => {
  const userID = store.getState().authReducer.user._id;
  if (userID) {
    PutRequest(`${changePasswordUrl}/${userID}`, data, {})
      .then((res) => {
        dispatch(
          setToasterState({
            appear: true,
            title: 'success',
            name: 'Success',
            message: 'Password Change Success',
          })
        );
      })
      .catch((err) => {
        dispatch(
          setToasterState({
            appear: true,
            title: 'Error',
            name: 'Change Password',
            message: 'Change Password Failed!',
          })
        );
      });
  }
};

const addImageInUser = (image: string) => (dispatch: any) => {
  const user: any = localStorage.getItem('user');
  const token: any = localStorage.getItem('token');
  let userObj = JSON.parse(user);
  userObj = {
    ...userObj,
    image: image,
  };
  dispatch(loginSuccess({ token: token, user: userObj }));
};

export const changeUserPicture = (payload: any) => (dispatch: any) => {
  const userID = store.getState().authReducer.user._id;
  if (userID) {
    const data = new FormData();
    data.append('image', payload);
    PutRequest(`${userUrl}/changePicture/${userID}`, data, {})
      .then((res) => {
        dispatch(addImageInUser(res.data.image));
        dispatch(
          setToasterState({
            appear: true,
            title: 'success',
            name: 'Success',
            message: 'Change Picture Success',
          })
        );
      })
      .catch((err) => {
        console.log(err);

        dispatch(
          setToasterState({
            appear: true,
            title: 'Error',
            name: 'Change Picture',
            message: 'Change Picture Failed!',
          })
        );
      });
  }
};

export const editOwnDetails = (payload: any) => (dispatch: any) => {
  const userID = store.getState().authReducer.user.uid;
  if (userID) {
    const data = {
      name: payload.name,
    };
    UpdateData(userUrl, userID, data)
      .then((res) => {
        dispatch(getUserDataFromId(userID));
        dispatch(
          setToasterState({
            appear: true,
            title: 'success',
            name: 'Success',
            message: 'Updating User Details Success',
          })
        );
      })
      .catch((err) => {
        console.log(err);

        dispatch(
          setToasterState({
            appear: true,
            title: 'Error',
            name: 'Change Picture',
            message: 'Change Picture Failed!',
          })
        );
      });
  }
};

export const addUsers = (payload: any = {}) => (dispatch: any) => {
  AddData(userUrl, payload)
    .then((response: any) => {
      dispatch(
        setToasterState({
          appear: true,
          title: 'success',
          name: 'User Registered',
          message: 'The provided information was saved',
        })
      );
    })
    .catch((error: any) => {
      console.error(error);
      dispatch(loginFailed());
      dispatch(
        setToasterState({
          appear: true,
          title: 'error',
          name: 'Authentication Error',
          message: 'Data was not saved',
        })
      );
    });
};
export const getInstituteInfo = (payload: any = {}) => (dispatch: any) => {
  if (payload) {
    GetRequest(userUrl + `/getInstituteInfo/${payload}`, {}, {})
      .then((response: any) => {
        dispatch(instituteInfoSuccess(response.data));
      })
      .catch((errors: any) => {
        dispatch(
          setToasterState({
            appear: true,
            title: 'error',
            name: 'Invalid Data',
            message: 'Cannot Get Institute Info',
          })
        );
      });
  }
};
export const authenticate = (payload: any = {}) => (dispatch: any) => {
  dispatch(setLoading(true));
  Signin(payload.email, payload.password)
    .then((res: any) => {
      dispatch(getUserDataFromId(res.user.uid));
    })
    .catch((errors: any) => {
      console.log(errors);

      dispatch(loginFailed());
      dispatch(
        setToasterState({
          appear: true,
          title: 'error',
          name: 'Authentication Error',
          message: errors?.message ? errors?.message : 'Invalid Credentials',
        })
      );
    });
};

export const reauthenticate = () => (dispatch: any) => {
  try {
    const unsubscribe = auth.onAuthStateChanged((user: any) => {
      if (user) {
        dispatch(getUserDataFromId(user.uid));
      } else {
        dispatch(
          setToasterState({
            appear: true,
            title: 'error',
            name: 'Authentication Error',
            message: 'Please Login again',
          })
        );
        logout()(dispatch);
      }
    });
    return unsubscribe;
  } catch (e) {
    dispatch(
      setToasterState({
        appear: true,
        title: 'error',
        name: 'Authentication Error',
        message: 'Please Login again',
      })
    );
    logout()(dispatch);
  }
};

export const logout = () => (dispatch: any) => {
  localStorage.removeItem('user');
  localStorage.removeItem('type');
  localStorage.removeItem('token');
  localStorage.removeItem('institute');
  SignOut();
  dispatch(logoutSuccess());
};

export const getUserDataFromId = (uid: string) => (dispatch: any) => {
  GetDocumentData(userUrl, uid)
    .then((res) => {
      dispatch(loginSuccess({ user: res.data() }));
      dispatch(setLoading(false));
      
    })
    .catch((err) => {
      dispatch(setLoading(false));
      dispatch(
        setToasterState({
          appear: true,
          title: 'error',
          name: 'Authentication Error',
          message: err?.message ? err?.message : 'Error retreving user data',
        })
      );
    });
};
