import React, { useEffect, useState } from 'react';
import ListTable from '../../../../common/Table';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../../store/action';
import Modal from '../../../../common/modal';
import Confirmation from '../../../../common/modal/Confirmation';
import EditUser from './components/EditUser';
import AddUsers from './components/AddUser';

const UserManagement = () => {
    const usersList = useSelector((state: any) => {
        return state.adminReducer.users;
    });

    const user = useSelector((state: any) => {
        return state.authReducer.user;
    });

    const dispatch = useDispatch();
    const [users, setUsers] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [editUserObj, setEditUserObj] = useState({ _id: -1 });
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [modalMode, setModalMode] = useState('add');

    useEffect(() => {
        user.uid && loadUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    useEffect(() => {
        setUsers(usersList);
    }, [usersList])

    const loadUsers = async () => {
        await dispatch(actions.adminGetAllUsers());
    }

    const deleteAction = (obj: any) => {
        setShowConfirmation(true);
        setEditUserObj(obj)
    }

    const deleteConfirmation = async () => {
        // await dispatch(actions.clientDeleteUser(editUserObj.id));
        setShowConfirmation(false);
    }

    const addAction = () => {
        setModalMode('add');
        setShowModal(true);
    }

    const editUser = async (payload: any) => {
        await dispatch(actions.adminEditUser(payload));
    }

    const editAction = (obj: any) => {
        setModalMode('edit');
        setEditUserObj(obj);
        setShowModal(true);

    }

    const columns = [
        {
            name: 'email',
            align: 'left',
            label: 'Email',
            field: 'email',
            flexVal: 2,
            sortable: true,
            splice: 20,
        }, {
            name: 'role',
            align: 'left',
            label: 'Role',
            field: 'role',
            flexVal: 1,
            sortable: true,
        }, {
            name: 'status',
            align: 'center',
            label: 'Status',
            field: 'status',
            flexVal: 1,
            splice: 20,
        }
    ]

    return (
        <section className="wrapper issues full-height full-width flex column py-md">
            <header className={'flex justify-between items-center mb-md'}>
                <div className="title-sm bold text-primary">User Management</div>
                <button className="btn primary" onClick={addAction}>Add User</button>
            </header>
            <div className="table-area pt-md flex-1">
                <ListTable
                    columns={columns}
                    rows={users}
                    editAction={editAction}
                    deleteAction={deleteAction}
                    paginate={10}
                />
            </div>
            {
                modalMode === 'add' ?
                    <Modal show={showModal} title={'Add User'}
                        closeModal={() => setShowModal(false)}>
                        <AddUsers closeModal={() => setShowModal(false)} mode={modalMode} />
                    </Modal> :
                    <Modal show={showModal} title={'Edit User'}
                        closeModal={() => setShowModal(false)}>
                        <EditUser
                            editUser={editUser}
                            editObj={editUserObj}
                            closeModal={() => setShowModal(false)}
                            mode={modalMode}
                        />
                    </Modal>
            }
            <Confirmation title="Confirm" content="Are you sure you want to delete this item?" show={showConfirmation}
                confirm={deleteConfirmation}
                closeModal={() => setShowConfirmation(false)} />
        </section>
    )
}

export default UserManagement;
