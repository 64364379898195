import React, { FormEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { ValidateForm, ValidateInput } from '../../../utils/customHooks/validateForm';
import LoginRules from '../LoginRules';
import * as actions from '../store/AuthActions';
import icon from '../../../assets/svgs/icon.svg';
interface ILogin {
    email: string,
    userName: string,
    password: string,
    conformPassword: string,
    phone: string
}

const initialValues: ILogin = {
    email: '',
    userName: '',
    password: '',
    conformPassword: '',
    phone: ''
};
const Signup = (props: any) => {

    const history = useHistory();
    const dispatch = useDispatch();
    const [errors, setErrors] = useState(initialValues);
    const [user, setUser] = useState(initialValues);
    const isAuthenticated = useSelector((state: any) => state.authReducer.isAuthenticated);
    const inputValidation = (e: any) => {
        let errorMsg = ValidateInput(e.target.name, e.target.value, LoginRules);
        setErrors({
            ...errors,
            [e.target.name]: errorMsg
        });
    }
    const inputHandler = (e: any) => {
        setUser({
            ...user,
            [e.target.name]: e.target.value
        });
    }

    useEffect(() => {
        if (isAuthenticated) {
            history.push('/');
        }
        // eslint-disable-next-line
    }, [isAuthenticated])
    const addusers = async (e: FormEvent) => {
        e.preventDefault();
        let errorMsgs: any = ValidateForm(user, LoginRules);
        setErrors({ ...errorMsgs });
        let validated = Object.values(errorMsgs).join('').length === 0;
        if (validated) {
            await dispatch(actions.addUsers({ ...user }))
        }
    }

    return (
        <section className="login full-vh full-width flex-centered">
            <form className="flex column justify-between relative z2" onSubmit={(e) => addusers(e)}>
                <div className="simplx-logo title-sm text-primary flex justify-center">
                    <img src={icon} alt={'logo'} />
                </div>
                <div className="form-area my-lg">
                    <div className="form-group mb-sm">
                        <label className={'bold text-primary'}>Email</label>
                        <div className="input-area flex bg-light items-center">
                            <input type="email"
                                name={"email"}
                                onBlur={inputValidation}
                                onChange={inputHandler}
                                placeholder={'Enter email'} />
                        </div>
                        {errors.email !== '' ? <span className="error-text">{errors.email}</span> : ''}
                    </div>
                    <div className="form-group mb-lg">
                        <label className={'bold text-primary'}>UserName</label>
                        <div className="input-area flex bg-light items-center">
                            <input type="text"
                                name={"userName"}
                                onBlur={inputValidation}
                                onChange={inputHandler}
                                placeholder={'Enter User Name '} />
                        </div>
                        {errors.userName !== '' ? <span className="error-text">{errors.userName}</span> : ''}
                    </div>
                    <div className="form-group mb-lg">
                        <label className={'bold text-primary'}>PhoneNo</label>
                        <div className="input-area flex bg-light items-center">
                            <input type="number"
                                name={"phone"}
                                onBlur={inputValidation}
                                onChange={inputHandler}
                                placeholder={'Enter Phone no'} />
                        </div>
                        {errors.phone !== '' ? <span className="error-text">{errors.phone}</span> : ''}
                    </div>
                    <div className="form-group mb-lg">
                        <label className={'bold text-primary'}>Password</label>
                        <div className="input-area flex bg-light items-center">
                            <input type="password"
                                name={"password"}
                                onBlur={inputValidation}
                                onChange={inputHandler}
                                placeholder={'Enter password'} />
                        </div>
                        {errors.password !== '' ? <span className="error-text">{errors.password}</span> : ''}
                    </div>
                    <div className="form-group mb-lg">
                        <label className={'bold text-primary'}>Conformation password</label>
                        <div className="input-area flex bg-light items-center">
                            <input type="password"
                                name={"conformPassword"}
                                onBlur={inputValidation}
                                onChange={inputHandler}
                                placeholder={'ReEnter password'} />
                        </div>
                        {errors.conformPassword !== '' ? <span className="error-text">{errors.conformPassword}</span> : ''}
                    </div>
                </div>
                <button className="btn primary full-width" type={'submit'}>Signup</button>
            </form>
        </section>
    )
}

export default Signup