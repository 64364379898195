import ImageHelper from '../../modules/common/component/ImageHelper';
import optionType from '../../modules/common/enum/optionType';
import './radio.css'
interface IRadioButton {
    name: string,
    text: string,
    value: string,
    checked: boolean,
    onChange: Function,
    onImageClick: Function,
    onBlur: Function,
    butttonNumber: string,
    image: string,
}
const RadioButton = (props: IRadioButton) => {
    return (
        <label className={`radio-container `}>
            {(props.image === optionType.IMAGE || props.image === optionType.LISTENING) &&
                <ImageHelper
                    onClick={props.onImageClick}
                    defaultListening={props.image === optionType.LISTENING}
                    style={{ maxWidth: '80%', maxHeight: '12rem', objectFit: 'contain' }}
                    className=""
                    image={props.text || ''}
                />
            }
            {props.image === optionType.TEXT &&
                props.text}

            <input type="radio"
                checked={props.checked}
                value={props.value}
                name={props.name}
                onBlur={(e) => props.onBlur(e)}
                onChange={(e) => props.onChange(e)} />
            <span className="checkmark">{props.butttonNumber}</span>
        </label>
    )
};

export default RadioButton