 // eslint-disable-next-line 
export default {
    username: {
        required: true,
        isEmail: true,
        label: 'Username'
    },
    first_name: {
        required: true,
        label: 'First Name'
    },
    last_name: {
        required: true,
        label: 'Last Name'
    },
    status: {
        required: true,
        label: 'Status'
    },
    user_type: {
        required: true,
        label: "User Type"
    },
    user_role: {
        required: true,
        label: 'User Role'
    },
}

