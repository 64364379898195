import React, { useEffect, useState } from 'react';
import ListTable from '../../../../common/Table';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from './services/ResultActions';
import { useHistory, useParams } from "react-router-dom";

const Results = () => {
    const resultList = useSelector((state: any) => {
        return state.adminReducer.results;
    });

    const history = useHistory()
    const { examId } = useParams<{ examId: string }>();

    const dispatch = useDispatch();
    const [results, setResults] = useState([]);

    useEffect(() => {
        dispatch(actions.getExamResultsAdminSuccess([]))
        examId && loadResults();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [examId]);

    useEffect(() => {

        setResults(resultList);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resultList])

    const loadResults = async () => {
        await dispatch(actions.getAdminResultsFromExam(examId));
    }

    const viewDetailsResult = async (res: any) => {
        history.push(`results/${res._id}`)
    }
    const goBack = async () => {
        history.goBack();
    }

    const searchHandler = (e: any) => {
        if (e.target.value) {
            filterResult(e.target.value);
        } else {
            setResults(resultList)
        }
    }
    const filterResult = (value: string) => {
        // throw new Error('Function not implemented.');
        const newRes = resultList.filter((r: any) => r?.username?.includes(value));
        setResults(newRes)
    }

    const columns = [
        {
            name: 'title',
            align: 'left',
            label: 'Title',
            field: 'title',
            flexVal: 1,
            sortable: true,
            splice: 5,
        }
        , {
            name: 'User Name',
            align: 'center',
            label: 'UserName',
            field: 'username',
            flexVal: 1,
            sortable: true,
            boxed: true
        }
        , {
            name: 'Score',
            align: 'center',
            label: 'Score',
            field: 'score',
            flexVal: 1,
            sortable: true,
            boxed: true
        }
        , {
            name: 'Exam Date',
            align: 'center',
            label: 'Date',
            field: 'timestamp',
            flexVal: 1,
            sortable: true,
            date: true,
            boxed: true
        }
    ]

    return (
        <section className="wrapper issues full-height full-width flex column py-md">
            <div>
                <button className="btn primary" onClick={goBack}>Back</button>
            </div>
            <header className={'flex justify-between items-center mb-md'}>
                <div className="title-sm bold text-primary">List of Results</div>
                <div className="title-sm bold text-primary">
                    <div className="form-group my-md">
                        <label>Search</label>
                        <input
                            type="text"
                            name={'question_number'}
                            placeholder={'Search by username'}
                            onChange={searchHandler}
                        />
                    </div>
                </div>
            </header>

            <div className="table-area pt-md flex-1">
                <ListTable
                    columns={columns}
                    rows={results}
                    paginate={10}
                    viewAction={viewDetailsResult}
                />
            </div>
            {/* {
                modalMode === 'add' ?
                    <Modal show={showModal} title={'Add Exam'}
                        closeModal={() => setShowModal(false)}>
                        <AddExam closeModal={() => setShowModal(false)} mode={modalMode} />
                    </Modal> :
                    <Modal show={showModal} title={'Edit User'}
                        closeModal={() => setShowModal(false)}>
                        <EditExam
                            editObj={editExamObj}
                            closeModal={() => setShowModal(false)}
                            editExam={editExam}
                            mode={modalMode}
                        />
                    </Modal>
            } */}
        </section>
    )
}

export default Results;


