import React from 'react';
import './tooltip.scss';

const Tooltip = (props: any) => {
    return(
        <>
        {
            <div className="hasTooltip">
                <div className="content">{props.children}</div>
                <div className={`bg-secondary card tool-tip-container ${props.location}`}>
                    {props.label}
                </div>
            </div>
        }
        </>
    )
}

export default Tooltip;