import { setToasterState } from '../../../../../store/action';
import store from '../../../../../store/store';
import {
  AddData,
  GetCollectionData,
  UpdateData,
} from '../../../../../plugins/firebasefirestore';

const examUrl = 'Exams';
export const getExamsAdminSuccess = (payload: any) => {
  return {
    type: 'GET_ADMIN_EXAMS',
    payload: payload,
  };
};

export const getAdminExams = () => (dispatch: any) => {
  const userID = store.getState().authReducer.user.uid;
  if (userID) {
    GetCollectionData(examUrl)
      .then((querySnapshot) => {
        const res = querySnapshot.docs.map((doc) => doc.data());
        dispatch(getExamsAdminSuccess(res));
      })
      .catch((err) => {
        dispatch(
          setToasterState({
            appear: true,
            title: 'error',
            name: 'Fetch Error',
            message: 'Error cannot get Exams',
          })
        );
      });
  }
};

export const addExam = (payload: any = {}) => (dispatch: any) => {
  const userID = store.getState().authReducer.user.uid;
  payload = {
    ...payload,
    user: userID,
  };
  AddData(examUrl, payload)
    .then((res) => {
      dispatch(
        setToasterState({
          appear: true,
          title: 'success',
          name: 'Success',
          message: 'Successfully added Exam',
        })
      );
      dispatch(getAdminExams());
    })
    .catch((err) => {
      dispatch(
        setToasterState({
          appear: true,
          title: 'error',
          name: 'Error Adding',
          message: 'Error adding exam',
        })
      );
    });
};

export const adminPublishExam = (examId: string) => (dispatch: any) => {
  const userID = store.getState().authReducer.user.uid;
  const payload = {
    status: 1,
  };
  if (userID) {
    UpdateData(examUrl, examId, payload)
      .then((res) => {
        dispatch(
          setToasterState({
            appear: true,
            title: 'success',
            name: 'Success',
            message: 'Successfully Updated Exam',
          })
        );
        dispatch(getAdminExams());
      })
      .catch((err) => {
        dispatch(
          setToasterState({
            appear: true,
            title: 'error',
            name: 'Error Adding',
            message: 'Error adding exam',
          })
        );
      });
  }
};
export const adminEditExam = (examId: string, payload: any = {}) => (
  dispatch: any
) => {
  const userID = store.getState().authReducer.user.uid;
  if (userID) {
    payload = {
      ...payload,
      user: userID,
    };
    UpdateData(examUrl, examId, payload)
      .then((res) => {
        dispatch(
          setToasterState({
            appear: true,
            title: 'success',
            name: 'Success',
            message: 'Successfully Updated Exam',
          })
        );
        dispatch(getAdminExams());
      })
      .catch((err) => {
        console.log(err);

        dispatch(
          setToasterState({
            appear: true,
            title: 'error',
            name: 'Error UPdating',
            message: 'Error updating exam',
          })
        );
      });
  }
};
