import { setToasterState } from '../../../../../store/action';
import store from '../../../../../store/store';
import { GetCollectionWhereData } from '../../../../../plugins/firebasefirestore';

const resultUrl = 'Results';
export const getExamResultsAdminSuccess = (payload: any) => {
  return {
    type: 'GET_ADMIN_RESULTS',
    payload: payload,
  };
};

export const getAdminResultsFromExam = (examId: string) => (dispatch: any) => {
  const userID = store.getState().authReducer.user.uid;
  if (userID) {
    GetCollectionWhereData(resultUrl, 'exam', '==', examId)
      .then((querySnapshot) => {
        const res = querySnapshot.docs.map((doc) => doc.data());
        const newResultList = res.map((r: any) => {
          let score = 0;
          r.answers.forEach((a: any) => {
            if (a.correct) {
              score++;
            }
          });
          return {
            ...r,
            score,
          };
        });
        dispatch(getExamResultsAdminSuccess(newResultList));
      })
      .catch((err) => {
        console.log(err);

        dispatch(
          setToasterState({
            appear: true,
            title: 'error',
            name: 'Fetch Error',
            message: 'Error cannot get Results',
          })
        );
      });
  }
};
