import * as actionTypes from '../store/AdminTypes';
interface IAdminUsers {
  questionAddLoad: false;
  questions: [];
  exams: [];
  examAccess: [];
  users: [];
  institutes: [];
  results: [];
  reports: [];
  listening: {};
}
const initailState: IAdminUsers = {
  questionAddLoad: false,
  exams: [],
  questions: [],
  examAccess: [],
  users: [],
  institutes: [],
  reports: [],
  results: [],
  listening: {},
};
// eslint-disable-next-line
export default (state: IAdminUsers = initailState, actions: any) => {
  switch (actions?.type) {
    case actionTypes.GET_ADMIN_EXAMS:
      return {
        ...state,
        exams: actions.payload,
      };
    case actionTypes.GET_QUESTIONS:
      return {
        ...state,
        questions: actions.payload,
      };
    case actionTypes.GET_USERS:
      return {
        ...state,
        users: actions.payload,
      };
    case actionTypes.GET_INSTITUTES:
      return {
        ...state,
        institutes: actions.payload,
      };
    case actionTypes.GET_LISTENING:
      return {
        ...state,
        listening: actions.payload,
      };
    case actionTypes.ADD_QUESTION:
      return {
        ...state,
        questions: [...state.questions, actions.payload],
      };
    case actionTypes.GET_ADMIN_RESULTS:
      return {
        ...state,
        results: actions.payload,
      };
    case actionTypes.GET_ADMIN_REPORT:
      return {
        ...state,
        reports: actions.payload,
      };
    case actionTypes.GET_ADMIN_EXAM_ACCESS:
      return {
        ...state,
        examAccess: actions.payload,
      };
    case actionTypes.QUESTION_ADD_LOADING:
      return {
        ...state,
        questionAddLoad: actions.payload,
      };
    default:
      return state;
  }
};
