import { setToasterState } from '../../../../../store/action';
import store from '../../../../../store/store';
import { GetCollectionDataSortLimitDesc } from '../../../../../plugins/firebasefirestore';

const reportUrl = 'Reports';
export const getHistoryAdminSuccess = (payload: any) => {
  return {
    type: 'GET_ADMIN_REPORT',
    payload: payload,
  };
};

export const getReportOfInstitute = (payload: number) => (dispatch: any) => {  
  const userID = store.getState().authReducer.user.uid;
  if (userID) {
    GetCollectionDataSortLimitDesc(reportUrl, 'timestamp', payload)
      .then((snapshot) => {
        const res = snapshot.docs.map((s) => s.data());
        dispatch(getHistoryAdminSuccess(res));
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          setToasterState({
            appear: true,
            title: 'error',
            name: 'Fetch Error',
            message: 'Error cannot get Results',
          })
        );
      });
  }
};
