import { useEffect, useState } from 'react';
import questionType from '../../../../common/enum/questiontype';
import { Answer } from '../../../../common/model/Answer';

interface IQuestionNumberLists {
    changeMode: Function;
    setListeningStatus: Function;
    answers: []
}

const ResultNumberLists = (props: IQuestionNumberLists) => {

    const [listenings, setListening] = useState<Answer[]>([])
    const [readings, setReadings] = useState<Answer[]>([])

    useEffect(() => {
        console.log(props.answers);
        
        setListening(props.answers.filter((a: any) => a.type === questionType.LISTENING))
        setReadings(props.answers.filter((a: any) => a.type === questionType.READING))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.answers])


    return (
        <div className="question-list">
            <div className="questions-container">
                Reading Questions
                <div>
                    {readings.map((q, key) => (
                        <div onClick={() => props.changeMode(key)} className={`text-light pointer ${q.answer !== '0' ? 'bg-danger' : 'bg-primary '} `}>
                            {q.question_number}
                        </div>
                    ))}
                </div>
            </div>

            <div className="questions-container">
                Listening Questions
                <div>
                    {listenings.map((q, key) => (
                        <div onClick={() => props.changeMode(readings.length + key)}
                            className={`text-light pointer ${q.answer !== '0' ? 'bg-danger' : 'bg-primary '} `}>
                            {q.question_number}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ResultNumberLists;
