import icon from "../../assets/svgs/icon.svg";
import { UserType } from '../enum/UserType.enum';

const Logo = (props: any) => {
    return (
        <div className='icon-logo py-md'>
            {props.role !== UserType.ADMIN ?
                <img src={props.img ? `${process.env.REACT_APP_API_BASE_URL}${props.img}` : icon} alt="" /> :
                <img src={icon} alt="" />}
        </div>
    )
}

export default Logo;