import { storage } from '../firebaseconfig';

export const uploadFile = async (path: string, file: File) => {
  if (file instanceof File) {
    const uploadTask = await storage
      .ref()
      .child(`${path}/${file.name}`)
      .put(file);
    return await uploadTask.ref.getDownloadURL();
  }

  // await uploadTask.on(
  //   'state_changed',
  //   (snapshot: any) => {
  //     console.log(snapshot);

  //   },
  //   (err) => {
  //     console.log(err);
  //   },
  //   async () => {
  //     const downloadUrl = await uploadTask.snapshot.ref.getDownloadURL();
  //     console.log(downloadUrl);

  //     return downloadUrl;
  //   }
  // );
};

export const deleteFile = (imageUrl: string) => {
  console.log(imageUrl);

  if (imageUrl) {
    try {
      const fileRef = storage.refFromURL(imageUrl);
      fileRef.delete().then((res) => {
        console.log('File Deleted');
      });
    } catch (e) {
      console.log(e);
    }
  }
};
