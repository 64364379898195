import React, { FormEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { ValidateForm, ValidateInput } from '../../../utils/customHooks/validateForm';
import LoginRules from '../LoginRules';
import * as actions from '../store/AuthActions';
import icon from '../../../assets/svgs/icon.svg';
import { UserType } from '../../../common/enum/UserType.enum';
import Loading from '../../../common/loading';

interface ILogin {
    email: string,
    password: string
}

const initialValues: ILogin = {
    email: '',
    password: '',
};
const Login = (props: any) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [errors, setErrors] = useState(initialValues);
    const [user, setUser] = useState(initialValues);
    const isAuthenticated = useSelector((state: any) => state.authReducer.isAuthenticated);
    const loading = useSelector((state: any) => state.authReducer.loading);
    const currentUser = useSelector((state: any) => state.authReducer.user);
    const inputValidation = (e: any) => {
        let errorMsg = ValidateInput(e.target.name, e.target.value, LoginRules);
        setErrors({
            ...errors,
            [e.target.name]: errorMsg
        });
    }
    const inputHandler = (e: any) => {
        setUser({
            ...user,
            [e.target.name]: e.target.value
        });
    }

    useEffect(() => {

        if (isAuthenticated && currentUser) {
            if (currentUser.role === UserType.USER) {
                history.push('/student');
            } else if (currentUser.role === UserType.ADMIN) {
                history.push('/admin');
            }
        }
        // eslint-disable-next-line
    }, [isAuthenticated])
    const authenticateUser = async (e: FormEvent) => {
        e.preventDefault();
        let errorMsgs: any = ValidateForm(user, LoginRules);
        setErrors({ ...errorMsgs });
        let validated = Object.values(errorMsgs).join('').length === 0;
        if (validated) {
            await dispatch(actions.authenticate({ ...user }))
        }
    }
    return (
        <section className="login full-vh full-width flex-centered">
            <form className="flex column justify-between relative z2" onSubmit={(e) => authenticateUser(e)}>
                <div className="simplx-logo title-sm text-primary flex justify-center">
                    <img src={icon} alt={'logo'} />
                </div>
                <div className="form-area my-lg">
                    <div className="form-group mb-sm">
                        <label className={'bold text-primary'}>Email</label>
                        <div className="input-area flex bg-light items-center">
                            <input type="email"
                                name={"email"}
                                onBlur={inputValidation}
                                onChange={inputHandler}
                                placeholder={'Enter email'} />
                        </div>
                        {errors.email !== '' ? <span className="error-text">{errors.email}</span> : ''}
                    </div>
                    <div className="form-group mb-lg">
                        <label className={'bold text-primary'}>Password</label>
                        <div className="input-area flex bg-light items-center">
                            <input type="password"
                                name={"password"}
                                onBlur={inputValidation}
                                onChange={inputHandler}
                                placeholder={'Enter password'} />
                        </div>
                        {errors.password !== '' ? <span className="error-text">{errors.password}</span> : ''}
                    </div>
                </div>
                <button className="btn primary full-width" type={'submit'} disabled={loading}>
                    {!loading ? <span>Login</span> : <Loading isLoading={true} />}
                </button>
            </form>
        </section>
    )
}

export default Login;