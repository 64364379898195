import React, { useEffect, useState } from 'react';
import { Link, NavLink, useHistory } from "react-router-dom";
import Logo from '../../../../../common/Logo/Logo';
import ProfileDefaultImg from '../../../../../assets/default.png';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../../auth/store/AuthActions';
import { UserType } from '../../../../../common/enum/UserType.enum';

const NavClient = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [role, setRole] = useState('');
    const [titleName, setTitleName] = useState('')

    const logoutUser = async () => {
        await dispatch(actions.logout());
        history.push("/");
    }
    const user: any = useSelector((state: any) => state.authReducer.user);
    const institute: any = useSelector((state: any) => state.authReducer.institute);
    useEffect(() => {
        if (user && user.role !== undefined) {
            if (user.role === UserType.USER) {
                setRole('Student');
                setTitleName(institute?.name || 'EPS CBT Exam')
            }else if (user.role === UserType.ADMIN) {
                setTitleName('EPS CBT Exam')
                setRole('Admin');
            }
        }
    }, [user, institute])
    return (
        <nav className={'px-md top-nav flex items-center justify-between'}>
            <div className="flex-1">
                <NavLink to="/" className="link-to-projects" exact>
                    <Logo role={user.role} img={institute?.image} />
                </NavLink>
            </div>
            <div className="nav-header title-sm flex-1 text-center">{titleName}</div>
            <div className="nav-profile flex-1 flex items-center justify-end">
                <div className="profile-item text-right">
                    <div className="font-small bold text-primary">{user.email}</div>
                    <div className="font-small-header-profile">{role}</div>
                </div>
                <button className="nav-profile-img profile-picture-card ml-sm">
                    <img src={user?.image ? `${process.env.REACT_APP_API_BASE_URL}${user.image}` : ProfileDefaultImg} alt={'img'} />
                    <div className="user-items card" >
                        <Link to={`/${role.toLowerCase()}/profile`} className="user-item">Profile</Link>
                        <div className="user-item" onClick={logoutUser}>Logout</div>
                    </div>
                </button>
            </div>
        </nav>
    );
}

export default NavClient;
