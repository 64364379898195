// eslint-disable-next-line
export default {
  question_number: {
    required: true,
    label: 'Question Number',
  },
  option1: {
    required: true,
    label: 'Option 1',
  },
  option2: {
    required: true,
    label: 'Option 2',
  },
  option3: {
    required: true,
    label: 'Option 3',
  },
  option4: {
    required: true,
    label: 'Option 4',
  },
  answer: {
    required: true,
    label: 'Answer',
  },
  listening: {
    file: true,
    required: true,
    label: 'Listening',
    maxSize: 5000000,
    types: ['audio/mpeg'],
  },
};
