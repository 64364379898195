// eslint-disable-next-line
export default {
  name: {
    required: true,
    label: 'Institute Name',
    minValue: 3,
  },
  email: {
    isEmail: true,
    required: true,
    label: 'Email',
  },
  password: {
    minValue: 8,
    required: true,
    label: 'Password',
  },
};
