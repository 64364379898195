
const ExamCard = (props: any) => {
    return (
        <div className="flex column justify-between card bg-light ma-md outline items-center" style={{ width: '200px' }}>
            <label className="bold text-center ma-sm">{props.title}</label>
            <label className="text-center mb-sm">{props.exam.type}</label>
            {props.exam?.user?.firstname && <label className="text-center mb-sm">{props.exam?.user?.firstname} {props.exam?.user?.lastname}</label>}
            <label className={`text-center mb-md bold  ${props.exam.status === 1 ? 'text-success' : 'text-danger'}`}>{props.exam.status === 1 ? 'Published' : 'Not Published'}</label>
            <div className="flexmb-md">
                {props.startAction && <button className="btn primary ml-xs mb-sm" onClick={() => props.startAction(props.exam)}>Start Exam</button>}
                {props.viewAction && <button className="btn primary ml-xs mb-sm" onClick={() => props.viewAction(props.exam)}>View</button>}
                {props.editAction && <button className="btn primary outlined ml-xs mb-sm" onClick={() => props.editAction(props.exam)}>Edit</button>}
            </div>
            {props.deleteAction && <button className="btn danger ml-xs mb-sm" onClick={() => props.deleteAction(props.exam)}>Delete Exam</button>}

        </div>
    );
};

export default ExamCard;
