import { setToasterState } from '../../../../../store/action';
import store from '../../../../../store/store';
import {
  GetCollectionData,
  SetData,
  UpdateData,
} from '../../../../../plugins/firebasefirestore';
import { Signup } from '../../../../../plugins/firebaseauth';

const userUrl = 'users';

export const adminGetAllUsersSuccess = (payload: any) => {
  return {
    type: 'GET_USERS',
    payload: payload,
  };
};

export const adminGetAllUsers = () => (dispatch: any) => {
  const userID = store.getState().authReducer.user.uid;
  if (userID) {
    GetCollectionData(userUrl)
      .then((querySnapshot) => {
        const res = querySnapshot.docs.map((doc) => doc.data());
        dispatch(adminGetAllUsersSuccess(res));
      })
      .catch((err) => {
        dispatch(
          setToasterState({
            appear: true,
            title: 'error',
            name: 'Fetch Error',
            message: 'Error cannot get Exams',
          })
        );
      });
  }
};

export const adminAddUser = (payload: any = {}) => (dispatch: any) => {
  const userID = store.getState().authReducer.user.uid;
  if (userID) {
    Signup(payload.email, payload.password)
      .then((res) => {
        const data = {
          uid: res.user?.uid,
          email: res.user?.email,
          role: 0,
          status: 'Active',
        };
        SetData(userUrl, data.uid ? data.uid : '', data).then((res) => {
          dispatch(adminGetAllUsers());
          dispatch(
            setToasterState({
              appear: true,
              title: 'success',
              name: 'Adding Success',
              message: 'Sucessfully Added User',
            })
          );
        });
      })
      .catch((err) => {
        dispatch(
          setToasterState({
            appear: true,
            title: 'error',
            name: 'Adding Error',
            message: 'Error Adding User',
          })
        );
      });
  }
};

export const adminEditUser = (payload: any = {}) => (dispatch: any) => {
  const userID = store.getState().authReducer.user.uid;
  payload = {
    ...payload,
    role: parseInt(payload.role),
  };

  if (userID) {
    UpdateData(userUrl, payload.uid, payload)
      .then((res) => {
        dispatch(adminGetAllUsers());
        dispatch(
          setToasterState({
            appear: true,
            title: 'success',
            name: 'Successfully Updated',
            message: 'Sucessfully Updated User',
          })
        );
      })
      .catch((err) => {
        console.log(err);

        dispatch(
          setToasterState({
            appear: true,
            title: 'error',
            name: 'Adding Error',
            message: 'Error Adding User',
          })
        );
      });
  }
};

export const adminDeleteUser = (id: number) => (dispatch: any) => {
  dispatch(
    setToasterState({
      appear: true,
      title: 'error',
      name: 'Fetch Error',
      message: 'Error While Fetching Users',
    })
  );
};
